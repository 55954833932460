<template>
  <v-app>
    <v-main>
      <div class="back-image">
        <span class="bg"></span>
        <span class="mask"></span>
      </div>
      <v-container fill-height grid-list-mdt text-xs-centert>
        <v-row align="center" justify="center">
          <v-spacer></v-spacer>
          <v-col md="8">
            <v-card outlined class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <h1>
                  Welcome ICEJ Live!
                </h1>
              </v-card-title>
              <v-card-title primary-title class="layout justify-center">
                <h2>
                  Please select the event you would like to access below:
                </h2>
              </v-card-title>
              <v-card-text class="buttons">
                <v-container>
                  <v-row align="center" justify="space-around">
                    <v-spacer></v-spacer>
                    <v-col
                      cols="12" lg="6" md="12" sm="12" xs="12"
                      v-for="(item, index) in routeItems"
                      :key="index"
                      class=""
                    >
                      <v-card color="#002e6d" class="card raised " :href="item.link" >
                        <v-img
                          class="shrink logo-img"
                          contain
                          alt="item.label"
                          :src="require('./assets/' + item.img)"
                        />
                        <v-card-title class="card-title">
                          {{ item.label }}
                        </v-card-title>
                      </v-card>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row align="center" justify="center">
          <v-spacer></v-spacer>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <a href="https://int.icej.org/" target="_blank">
              <v-img
                class="shrink logo-img"
                contain
                alt="ICEJ logo"
                :src="require('./assets/ice-jlogo-wide.svg')"
              />
            </a>
          </v-col>
          <v-spacer></v-spacer>
          </v-row>

      </v-container>
    </v-main>
  </v-app>
  <!-- <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app> -->
</template>

<script>
//import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    //HelloWorld,
  },

  data: () => ({
    //
    routeItems: [
      {
        label: 'Envision 2021',
        link: 'https://envision-live.icej.org',
        img: 'envision-white-outline.svg'
      },
      {
        label: 'Feast 2020',
        link: 'https://feast-live.icej.org',
        img: 'feastlogo-white-outline.625bbd4c.svg'
      },
    ]
  }),
};
</script>

<style scoped>
.back-image .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-color: white;
  /* transform: scale(1.1); */
  /* opacity: 0.09; */
  object-fit: cover;
  background-image: url('./assets/1_Onboarding-1.jpg');
}


.back-image .mask {
  /* background-image: linear-gradient(181deg, #002e6d 99%, #000000 1%); */
  /* background-image: linear-gradient(181deg, rgba(0, 46, 109, 0.7) 99%, rgba(0, 0, 0, 0.7) 1%); */
  background-image: linear-gradient(181deg, rgba(0, 0, 0, 0.4) 99%, rgba(0, 0, 0, 0.4) 1%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-img {
  min-height: 120px;
  margin: 1em;
}
h1, h2 {
  color: white;
  word-break: break-word;
  text-align: center;
}
.card-title {
  text-align: center;
  color: white;
  width: 100%;
  display: block;
}
</style>
